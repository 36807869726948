
import { Options, Vue } from 'vue-class-component';
import { apiReaderSoftwareGetList,
         apiReaderSoftwareGetListTotalNum } from '../services/tpsApi/tpsApiReaderSoftware';
import { DEFAULT_START_PAGE, DEFAULT_ITEMS_PER_PAGE } from '../services/common/IPaganation';
import { PAGENO } from '@/services/common/IPageNo.js';
import { exportFile } from '../services/exportFile';
import { IReaderSoftwareList } from '../services/tpsApi/models/readerSoftware';
import Alert from '@/components/Alert.vue';
import Table from '@/components/Table.vue';
import ActionMenu from '@/components/ActionMenu.vue';
import ReaderSoftwareForm from '@/components/ReaderSoftwareForm.vue';
import Pagination from '@/components/Pagination.vue';

@Options({
  components: {
    Alert,
    Table,
    ActionMenu,
   ReaderSoftwareForm,
    Pagination
  },
})
export default class ReaderSoftware extends Vue {
    $refs!: {
        form: ReaderSoftwareForm,
        alert: Alert
    }

    TABLE_HEAD: {} = {
        version:"版本號",
        name: "卡機軟體版本名稱",
        type: "檔案類型",
        desc: "說明",
        modified_user: "上傳人",
        modified_time: "上傳時間",
        size: "檔案大小",
        invalid: "狀態"
    };
    
    //Pagination prop
    pageTotalNum: number = 0;
    currentPage: number = DEFAULT_START_PAGE;
    currentLimit: number = DEFAULT_ITEMS_PER_PAGE;
    
    formVisible: boolean = false;
    readerSoftwareList: IReaderSoftwareList | Blob = [];
    filter: string | undefined = undefined;
    pageNo: {} = PAGENO;
    
    mounted() {
        this.refreshTabel({limit: this.currentLimit, page: this.currentPage});
        this.initPagination();
    }

    async initPagination() {
        this.pageTotalNum = await apiReaderSoftwareGetListTotalNum({filter: this.filter});
    }

    async showForm(pData: { actionType: string, id: number | null }) {
        this.$refs.form.initForm(pData.actionType,pData.id);
        this.formVisible = true;
    }
    
    hideForm(pData: { actionType: string, alertType: string, msg: string}) {
        this.formVisible = false;
        this.doAlert({alertType: pData.alertType, msg: pData.msg});
        this.refreshTabel({limit: this.currentLimit, page: this.currentPage});
        if(pData.actionType == "create") this.initPagination();
    }

    async refreshTabel(pData: {limit: number, page: number}) {
        if(pData && pData.limit) this.currentLimit = pData.limit;
        if(pData && pData.page) this.currentPage = pData.page;
        pData = Object.assign(pData, {filter:this.filter});
        this.readerSoftwareList = await apiReaderSoftwareGetList(pData);
    } 
    
    // async download(pData: { output: string }) {
    //     const res = await apiReaderSoftwareGetList({output: pData.output, filter: this.filter});
    //     exportFile({ fileName: "營運參數", data: res });
    // }

    doSearch(pData: {filter: string}) {
        this.filter = pData.filter;
        this.refreshTabel({limit: DEFAULT_ITEMS_PER_PAGE, page: DEFAULT_START_PAGE});
        this.initPagination();
    }

    doAlert(pData: {alertType: string, msg: string}) {
        this.$refs.alert.doAlert(pData.alertType, pData.msg);
    }
}
