
import { Options, Vue } from 'vue-class-component';
import { Prop, Emit,Ref } from 'vue-property-decorator';
import { apiReaderSoftwareGetDetail,
         apiReaderSoftwareDetailUpdate,
         apiReaderSoftwareCreate } from '../services/tpsApi/tpsApiReaderSoftware';
import { IReaderSoftwareDetail } from '../services/tpsApi/models/readerSoftware';
import { arrangeUpdateData } from '../services/arrangeUpdateData';
//import DatepickerLite from 'vue3-datepicker-lite';
import WarningModal from '@/components/WarningModal.vue';

@Options({
  components: {
    //DatepickerLite,
    WarningModal
  },
  emits: ["hide-form"]
})

export default class ReaderSoftwareForm extends Vue {
    $refs!: {
        warning: WarningModal,
        upload
    }
    @Ref() upload!: HTMLInputElement;
    @Prop({ required: true }) showForm: boolean = false;
    WRITE: number | undefined = undefined;
    originalData: IReaderSoftwareDetail = {} as any;
    formData: IReaderSoftwareDetail = {} as any;
    nameWarning: string | null = null;
    actionType: string = "";
    ruleID: number = -1;
    file: string | Blob = "";
    fileName: string = "";
    TYPE_OPTIONS: Array<object>  = [
        {label: '軟體', value: 1},
        {label: '韌體', value: 2}
    ];

    

    async beforeMount() {
        this.WRITE = this.$store.getters.write;
    }
    async initForm(pActionType, pRuleID) {
        this.actionType = pActionType;
        this.ruleID = pRuleID;
        this.file = "";
        this.fileName = "";
        if(pRuleID) {
            this.formData = Object.assign({}, await apiReaderSoftwareGetDetail({id: pRuleID}));
            this.originalData = Object.assign({}, this.formData);
        }
        else {
            this.formData = {} as any;
            this.formData["invalid"] = 0;
        }    
    }
    
    async updateDetail(pSave: boolean) {
        if(pSave) {
            let data = {} as any;
           
            let dt = new Date();
            this.formData["modified_time"] = `${
                (dt.getMonth()+1).toString().padStart(2, '0')}-${
                dt.getDate().toString().padStart(2, '0')}-${
                dt.getFullYear().toString().padStart(4, '0')} ${
                dt.getHours().toString().padStart(2, '0')}:${
                dt.getMinutes().toString().padStart(2, '0')}:${
                dt.getSeconds().toString().padStart(2, '0')}`;
            this.formData["modified_user"] = this.$store.getters.currentUser;
            if(this.actionType == "update") {
                let res = await apiReaderSoftwareDetailUpdate({id: this.ruleID, data: arrangeUpdateData(this.formData,this.originalData)});
                if(res.msg === ("ok")) this.hideForm(true);
                else this.hideForm(false);
                if(res.msg === ("duplicated")) this.nameWarning = "此名稱已存在";  
            }      
            else if(this.actionType == "create") {
                let res = await apiReaderSoftwareCreate(this.formData,this.file);
                if(res.msg === ("ok")) this.hideForm(true);
                else this.hideForm(false);
                if(res.msg === ("duplicated")) this.nameWarning = "此名稱已存在";  
            }
               
        }
        if(pSave == false) {
            this.hideForm(false);
        }
    }

    showWarningModal() {
        if(JSON.stringify(this.originalData) !== JSON.stringify(this.formData)) {
            this.$refs.warning.setModalVisible(true);
        }
        else {
            this.updateDetail(false);
        }
    }

    @Emit("hide-form")
    hideForm(pSuccess) {
        this.$refs.upload.value = ''
        if(pSuccess) return { actionType: this.actionType, alertType:"success", msg:"成功儲存" };
    }

    handleCancel() {   
        this.hideForm(false);
    }

    getWriteProp() {
        if(this.actionType === 'create') return true;
        return this.WRITE===1?true:false;
    }
    async fileAppend() {
        if(this.upload.files) {
            let regExp: RegExp = /^$/;
            if(this.formData.type == 1) regExp = /^program\.\d+\.zip/;
            else regExp = /^firmware\.\d+\.zip/;
            if (!regExp.test(this.upload.files[0].name)) {
                alert("上傳錯誤：檔案名稱格式不符");
            }
            else{
                this.fileName = this.upload.files[0].name;
                [this.file] = this.upload.files;
            }
        }
    }
}
