import tpsApiInstance from "./tpsApiBase";
import {
    IReaderSoftwareList,
    IReaderSoftwareDetail,
    IReaderSoftwareGetListTotalNumRequestParams,
    IReaderSoftwareGetListRequestParams,
    IReaderSoftwareGetDetailRequestParams,
    IReaderSoftwareDetailUpdateRequestParams,
    IReaderSoftwareCreateResponseSchema,
    IReaderSoftwareDetailUpdateResponseSchema
} from './models/readerSoftware';

const LIST_READER_SOFTWARE = "/listReaderSoftware";
const GET_READER_SOFTWARE = "/getReaderSoftware";
const UPDATE_READER_SOFTWARE = "/updateReaderSoftware";
const CREATE_READER_SOFTWARE = "/createReaderSoftware";

export const apiReaderSoftwareGetListTotalNum = async(
    pData: IReaderSoftwareGetListTotalNumRequestParams): Promise<number> => {
    let query: string = "?count=total";
    if(pData && pData.filter) query+= `&filter=${pData.filter}`;
    
    const res = await tpsApiInstance.get(`${LIST_READER_SOFTWARE}${query}`);
    return res?.data[0]?.total;
};

export const apiReaderSoftwareGetList = async(
    pData: IReaderSoftwareGetListRequestParams): Promise<IReaderSoftwareList> => {

    let query: string = "?";
    //if(pData && pData.status) query += `status=${pData.status}`;
    if(pData && pData.limit && pData.page) query += `&limit=${pData.limit}&offset=${(pData.page-1)*pData.limit}`;
    if(pData && pData.filter) query+= `&filter=${pData.filter}`;
    console.log(query);
    const res = await tpsApiInstance.get(`${LIST_READER_SOFTWARE}${query}`);
    console.log(res);
    return res?.data as IReaderSoftwareList;
};

export const apiReaderSoftwareGetDetail = async(
    pData:IReaderSoftwareGetDetailRequestParams): Promise<IReaderSoftwareDetail> => {
    const res = await tpsApiInstance.get(`${GET_READER_SOFTWARE}?id=${pData.id}`);
    console.log(res?.data);
    return res?.data as IReaderSoftwareDetail;
};

export const apiReaderSoftwareCreate = async(
    pData: IReaderSoftwareDetail,pFiles: any): Promise<IReaderSoftwareCreateResponseSchema> => {
    console.log(pData);
    const res = await tpsApiInstance.post(CREATE_READER_SOFTWARE,  setformData(pData,pFiles));
    console.log(res);
    return res?.data as IReaderSoftwareCreateResponseSchema;
};

export const apiReaderSoftwareDetailUpdate = async (
    pData: IReaderSoftwareDetailUpdateRequestParams
    ): Promise<IReaderSoftwareDetailUpdateResponseSchema> => {
    console.log("Update:",JSON.stringify(pData));
    const res = await tpsApiInstance.post(`${UPDATE_READER_SOFTWARE}?id=${pData.id}`, pData.data);
    console.log(res);
    return res.data as IReaderSoftwareDetailUpdateResponseSchema;
  };

export const apiReaderSoftwareGetVersion = async(): Promise<IReaderSoftwareList> => {
const res = await tpsApiInstance.get(`listReaderSoftware`);
const listNum = {};
if(res.data) {
    let i;
    for (i = 0; i < res.data.length; i+=1) {
        listNum[res.data[i].version] = res.data[i].version;
    }
}
return listNum;
};

function setformData(pData: any, pFiles: any) {
    const formData = new FormData();
  
    Object.keys(pData).forEach(function(key) {
      formData.append(key, pData[key]);
    });
    
    if(pFiles) formData.append("files",pFiles);
    return formData;
  }
  
